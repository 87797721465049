import React, { useState, useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, Element, animateScroll as scroll } from 'react-scroll';

export default function NewResearch() {
  const [activeSection, setActiveSection] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 100); // 1 second loading time

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll('section');
      
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollPosition >= sectionTop - 50 && scrollPosition < sectionTop + sectionHeight - 50) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);

  

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className={`transition-opacity duration-1000 ${loading ? 'opacity-0' : 'opacity-100'}`}>
            <div className="min-h-screen bg-[#0C0C0C] text-[#A3A3A3] font-[system-ui]">
            <a className="p-2 text-[10px]" href="/research"><span aria-hidden="true">&larr;</span> Research </a>
        

        {/* Navigation */}
        <nav className="fixed left-8 top-12 space-y-4 hidden lg:block">
            <button onClick={scrollToTop} className="block text-[15px] hover:text-white transition-colors">Results</button>
            <Link to="methods" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'methods' ? 'text-white' : ''}`}>Methods</Link>
            <Link to="preferences" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'preferences' ? 'text-white' : ''}`}>Generalizing</Link>
            <Link to="limitations" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'limitations' ? 'text-white' : ''}`}>Limitations</Link>
            <Link to="next-steps" spy={true} smooth={true} duration={500} className={`block text-[15px] hover:text-white transition-colors ${activeSection === 'next-steps' ? 'text-white' : ''}`}>Next steps</Link>
        </nav>

        {/* Main Content */}
        <main className="max-w-[900px] mx-auto px-8 py-12">
            <div className="space-y-12">
            <Element name="results">
                <h2 className="text-[28px] text-white mb-6">diAI's Few-Shot Prompting Results</h2>
                <p className="text-[17px] leading-relaxed mb-8">
                To measure the safety of our models, we primarily use a suite of existing metrics on publicly available datasets. Compared to GPT-3, InstructGPT produces fewer imitative falsehoods (according to TruthfulQA¹⁷) and are less toxic (according to RealToxicityPrompts¹⁸). We also conduct human evaluations on our API prompt distribution, and find that InstructGPT makes up facts ("hallucinates") less often, and generates more appropriate outputs.ᶜ
                </p>

                {/* Metrics Grid */}
                <div className="grid grid-cols-2 gap-x-16 gap-y-12">
                {/* RealToxicity */}
                <div>
                    <div className="text-[13px] uppercase tracking-wider mb-2">Dataset</div>
                    <h3 className="text-[22px] text-white mb-6">RealToxicity</h3>
                    
                    <div className="space-y-6">
                    <div>
                        <div className="flex justify-between mb-2">
                        <span>GPT</span>
                        <span>0.233</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[40%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>Supervised Fine-Tuning</span>
                        <span>0.199</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[35%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>InstructGPT</span>
                        <span>0.196</span>
                        </div>
                        <div className="h-1 bg-[#0066FF] w-[34%]"></div>
                    </div>
                    </div>
                </div>

                {/* TruthfulQA */}
                <div>
                    <div className="text-[13px] uppercase tracking-wider mb-2">Dataset</div>
                    <h3 className="text-[22px] text-white mb-6">TruthfulQA</h3>
                    
                    <div className="space-y-6">
                    <div>
                        <div className="flex justify-between mb-2">
                        <span>GPT</span>
                        <span>0.224</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[30%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>Supervised Fine-Tuning</span>
                        <span>0.206</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[28%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>InstructGPT</span>
                        <span>0.413</span>
                        </div>
                        <div className="h-1 bg-[#0066FF] w-[55%]"></div>
                    </div>
                    </div>
                </div>

                {/* Hallucinations */}
                <div>
                    <div className="text-[13px] uppercase tracking-wider mb-2">API Dataset</div>
                    <h3 className="text-[22px] text-white mb-6">Hallucinations</h3>
                    
                    <div className="space-y-6">
                    <div>
                        <div className="flex justify-between mb-2">
                        <span>GPT</span>
                        <span>0.414</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[45%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>Supervised Fine-Tuning</span>
                        <span>0.078</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[10%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>InstructGPT</span>
                        <span>0.172</span>
                        </div>
                        <div className="h-1 bg-[#0066FF] w-[20%]"></div>
                    </div>
                    </div>
                </div>

                {/* Customer Assistant Appropriate */}
                <div>
                    <div className="text-[13px] uppercase tracking-wider mb-2">API Dataset</div>
                    <h3 className="text-[22px] text-white mb-6">Customer Assistant Appropriate</h3>
                    
                    <div className="space-y-6">
                    <div>
                        <div className="flex justify-between mb-2">
                        <span>GPT</span>
                        <span>0.811</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[85%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>Supervised Fine-Tuning</span>
                        <span>0.880</span>
                        </div>
                        <div className="h-1 bg-[#333333] w-[90%]"></div>
                    </div>

                    <div>
                        <div className="flex justify-between mb-2">
                        <span>InstructGPT</span>
                        <span>0.902</span>
                        </div>
                        <div className="h-1 bg-[#0066FF] w-[95%]"></div>
                    </div>
                    </div>
                </div>
                </div>

                <p className="text-[15px] leading-relaxed opacity-60 mt-8">
                Evaluating InstructGPT for toxicity, truthfulness, and appropriateness. Lower scores are better for toxicity and hallucinations, and higher scores are better for TruthfulQA and appropriateness. Hallucinations and appropriateness are measured on our API prompt distribution. Results are combined across model sizes.
                </p>
            </Element>

            <Element name="methods">
                <section id="methods" className="pt-12">
                <h2 className="text-[28px] text-white mb-6">Methods</h2>
                <p className="text-[17px] leading-relaxed mb-6">
                    Our research methodology involved a comprehensive approach to evaluate the performance and safety of InstructGPT compared to other models. We employed the following methods:
                </p>
                <ul className="list-disc pl-6 space-y-4 text-[17px]">
                    <li>Utilized publicly available datasets such as TruthfulQA and RealToxicityPrompts to assess truthfulness and toxicity.</li>
                    <li>Conducted human evaluations on our API prompt distribution to measure hallucinations and appropriateness.</li>
                    <li>Implemented a series of controlled experiments to compare InstructGPT with GPT-3 and models trained with supervised fine-tuning.</li>
                    <li>Developed custom metrics to quantify the model's ability to follow instructions and generate appropriate responses.</li>
                </ul>
                </section>
            </Element>

            <Element name="preferences">
                <section id="preferences" className="pt-12">
                <h2 className="text-[28px] text-white mb-6">Generalizing to broader preferences</h2>
                <p className="text-[17px] leading-relaxed mb-6">
                    While our initial results are promising, we recognize the importance of generalizing our findings to a broader set of preferences and use cases. To address this, we:
                </p>
                <ul className="list-disc pl-6 space-y-4 text-[17px]">
                    <li>Expanded our testing to include diverse cultural contexts and languages.</li>
                    <li>Collaborated with domain experts to evaluate the model's performance in specialized fields such as medicine, law, and education.</li>
                    <li>Conducted longitudinal studies to assess the model's ability to adapt to changing societal norms and preferences over time.</li>
                </ul>
                </section>
            </Element>

            <Element name="limitations">
                <section id="limitations" className="pt-12">
                <h2 className="text-[28px] text-white mb-6">Limitations</h2>
                <p className="text-[17px] leading-relaxed mb-6">
                    Despite the advancements demonstrated by InstructGPT, we acknowledge several limitations in our current research:
                </p>
                <ul className="list-disc pl-6 space-y-4 text-[17px]">
                    <li>The model's performance may vary in real-world applications that differ significantly from our testing environments.</li>
                    <li>Ethical considerations and potential biases in the training data require ongoing scrutiny and mitigation efforts.</li>
                    <li>The computational resources required for training and running these models pose scalability challenges.</li>
                    <li>Long-term effects of widespread adoption of instruction-following AI models are not yet fully understood.</li>
                </ul>
                </section>
            </Element>

            <Element name="next-steps">
                <section id="next-steps" className="pt-12">
                <h2 className="text-[28px] text-white mb-6">Next steps</h2>
                <p className="text-[17px] leading-relaxed mb-6">
                    Building on our current findings, we propose the following next steps for advancing the field of instruction-following AI:
                </p>
                <ul className="list-disc pl-6 space-y-4 text-[17px]">
                    <li>Develop more sophisticated evaluation frameworks that can capture nuanced aspects of language understanding and generation.</li>
                    <li>Explore techniques for reducing the computational requirements of these models without sacrificing performance.</li>
                    <li>Investigate methods for improving the model's ability to understand and adhere to complex, multi-step instructions.</li>
                    <li>Conduct interdisciplinary research to better understand and mitigate potential societal impacts of widespread AI deployment.</li>
                </ul>
                </section>
            </Element>
            </div>
        </main>
        </div>

    </div>
    
  );
}